<template>
  <footer>
    <news-ticker
      v-if="newsTickerEnabled"
      extend-icon="mdi-plus"
      extend-text="Watch Ticker"
      :news-items="securityNewsItems"
      :reverse="true"
      @extend-clicked="onAddSecurityNewsItem"
    />
  </footer>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { NewsItem, UXConfig } from '@/utils/helpers/rest';
import { mapActions, mapState } from 'vuex';
import NewsTicker from '@/modules/news-ticker/components/NewsTicker.vue';

@Component({
  components: {
    NewsTicker,
  },
  computed: {
    ...mapState(['uxConfig', 'marketNewsItems', 'securityNewsItems']),
  },
  methods: {
    ...mapActions(['fetchMarketNews', 'fetchMarketNews', 'fetchSecurityNews']),
  },
})
export default class DashboardFooter extends Vue {
  protected marketNewsItems!: NewsItem[];
  protected securityNewsItems!: NewsItem[];
  private uxConfig!: UXConfig;

  private fetchMarketNews!: () => Promise<void>;
  private fetchSecurityNews!: () => Promise<void>;

  protected get newsTickerEnabled(): boolean {
    return this.uxConfig.hasNewsTicker;
  }

  protected mounted(): void {
    // load market data and watched securities
    void this.fetchMarketNews();
    void this.fetchSecurityNews();
  }

  protected onAddSecurityNewsItem(): void {
    this.$dialog.confirm({
      title: 'Watch Ticker',
      message: 'This feature is coming soon...',
    });
  }
}
</script>

<style lang="scss">
footer:empty {
  display: none;
}
</style>
