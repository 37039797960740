<template>
  <div class="d-flex flex-column flex fill-height">
    <!-- router child view -->
    <router-view class="d-flex flex flex-column overflow-y-auto"></router-view>

    <dashboard-footer class="shrink mt-2" />
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import DashboardFooter from '@/modules/dashboard/components/DashboardFooter.vue';

@Component({
  components: {
    DashboardFooter,
  },
})
export default class Dashboard extends Vue {}
</script>

<style lang="scss"></style>
