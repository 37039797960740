<template>
  <v-hover v-slot="{ hover }">
    <marquee-text :duration="duration" :paused="hover" :repeat="repeat" :reverse="reverse">
      <div>
        <div
          v-for="item in newsItems"
          :key="item.title"
          class="d-inline clickable px-2"
          @click="$emit('item-clicked', item)"
        >
          <span class="text-caption">{{ item.title }}</span>
          <v-chip :class="['ma-0 ml-2 pa-1', getColor(item)]" label x-small>
            {{ getLabel(item) }} {{ getChange(item) }}
          </v-chip>
        </div>
        <div v-if="extendText && newsItems.length" class="d-inline clickable px-2">
          <v-btn color="secondary" outlined x-small @click="$emit('extend-clicked')">
            <v-icon v-if="extendIcon" x-small>{{ extendIcon }}</v-icon>
            {{ extendText }}
          </v-btn>
        </div>
      </div>
    </marquee-text>
  </v-hover>
</template>

<script lang="ts">
/**
 * NewsTicker is a small component capable of scroll elements horizontally
 * @TODO: watch news to automatically update the ticker
 */
import { Component, Vue, Watch } from 'vue-property-decorator';
import MarqueeText from 'vue-marquee-text-component';
import { NewsItem } from '@/utils/helpers/rest';
import Decimal from 'decimal.js';
import { formatDecimalAsCurrencyString } from '@/utils/helpers/auction-numbers';

@Component({
  components: { MarqueeText },
  props: {
    reverse: Boolean,
    newsItems: Array,
    extendText: String,
    extendIcon: String,
  },
  data: () => ({
    tickerKey: 1, // increment when 'items' are updated to enforce repaint
    repeat: 1,
    duration: 15,
  }),
})
export default class NewsTicker extends Vue {
  private newsItems!: NewsItem[];
  private repeat!: number;
  private duration!: number;

  @Watch('newsItems')
  protected onChangedItems(): void {
    this.repeat = Math.ceil(20 / (this.newsItems.length + 1));
    this.duration = (this.newsItems.length + 1) * 5;
  }

  protected mounted(): void {
    this.onChangedItems();
  }

  protected getColor(item: NewsItem): string {
    const pct = item.change.div(item.value).mul(new Decimal(100.0));
    // this.$log.debug('item value=' + item.value + '; change=' + item.change + '; pct=' + pct);
    if (pct.toNumber() > 0.25) {
      return 'success';
    } else if (pct.toNumber() < -0.25) {
      return 'error';
    } else {
      return 'info';
    }
  }

  protected getLabel(item: NewsItem): string {
    // return (item.value + item.change).toFixed(2);
    return this.formatPrice(item.value, 2);
  }

  protected getChange(item: NewsItem): string {
    const pct = item.change.div(item.value).mul(new Decimal(100.0));
    if (pct.abs().toNumber() > 0.25) {
      return `${pct.isPositive() ? '+' : ''}${pct.toFixed(1)}%`;
    }
    return '';
  }

  private formatPrice(price: Decimal, precision: number): string {
    return formatDecimalAsCurrencyString('USD', price, precision);
  }
}
</script>

<style lang="scss" scoped>
.clickable {
  cursor: pointer;

  span {
    cursor: pointer;
  }
}
</style>
