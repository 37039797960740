import { MarketTimeframes } from '@/modules/market-closed/types/market-closed';
import { ActionKeys } from '@/store/actions';
import { MutationKeys } from '@/store/mutations';
import { AuctionStatus } from '@/utils/helpers/buffers';
import Decimal from 'decimal.js';

export interface SocketMessage<T> {
  /*
   * either Action or Mutation should be set,
   *  these are the intended actions/mutations in the store
   *
   * @TODO should fix those action/mutation values. Better to be null when uneeded
   */
  action: ActionKeys | '';
  mutation: MutationKeys | '';

  // Depending on which Action/Mutation the Message contains the appropriate payload
  payload: T;
}

export interface SocketToken {
  loginToken: string;
}

export interface SocketCompanyKey {
  pubKey: string;
}

export interface SocketNotification {
  message: string;
  notificationRefID: string | null;
  notificationOnClick: string | null;
}

export interface AuctionStatusMutation {
  auctionId: string;
  status: AuctionStatus;
}

export interface PriceUpdate {
  ticker: string;
  price: Decimal;
}

export interface JWT {
  refreshToken: string;
  accessToken: string;
}

export function normalizePriceUpdate(u: PriceUpdate): void {
  // input from server will have rate a string, we convert it to Decimal
  const uWithStrPrice: { price: string | Decimal } = u;
  if (typeof uWithStrPrice.price === 'string') {
    u.price = new Decimal(uWithStrPrice.price);
  }
}

export interface SocketMessagesOutgoing {
  socketConfirmIdentityWait: string;
  subscribeToPriceUpdate: string;
  sessionHeartbeat: string;
  authenticate: string;
}

export interface SocketMessagesIncoming {
  refreshCurrentUser: undefined;

  refreshOpenAuctions: string;
  refreshClosedAuctions: string;
  refreshAuctionsResults: string;

  refreshManualLoanRequests: string;
  refreshBorrowerLoan: number;
  refreshBorrowerOrders: string;
  refreshLenderLoan: number;
  refreshLenderOrders: string;

  refreshBrokerLoan: number;
  refreshBrokerLenderOrders: string;
  refreshBrokerBorrowerOrders: string;

  refreshNotifications: 'new auction result' | SocketNotification;
  socketWhitelistContinue: SocketToken;
  sendJWT: JWT;
  userDisabled: 'user disabled';
  refreshClientConfig: 'feature flag was changed';
  refreshMarketTimeframes: MarketTimeframes;
  refreshFrontendHash: string;

  refreshOmsOrders: { orderRef: string };

  refreshCompanies: null;
  refreshUsers: null;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  SOCKET_AUCTION_STATUS: AuctionStatusMutation;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  SOCKET_PRICE_UPDATE: PriceUpdate;
}
