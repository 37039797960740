<template>
  <v-card>
    <v-card-title>
      <span class="headline">{{ $t('userSettingsDesktop.description') }}</span>
    </v-card-title>
    <v-card-text>
      <v-row v-if="clientConfig.demoMode">
        <v-col>
          <h2>Theme</h2>

          <v-switch
            v-model="$vuetify.theme.dark"
            hint="This toggles the color theme between a light and dark mode"
            inset
            label="Dark Theme"
            persistent-hint
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <h2>Features</h2>

          <v-switch
            v-if="clientConfig.demoMode"
            v-model="hasGlobalSearch"
            hint="Display the global search bar"
            inset
            label="Show Global Search"
            persistent-hint
          />

          <v-switch
            v-if="clientConfig.demoMode"
            v-model="hasNewsTicker"
            hint="Display the news ticker"
            inset
            label="Show News Ticker"
            persistent-hint
          />

          <v-switch
            v-if="clientConfig.demoMode && clientConfig.createAuctionsEnabled"
            v-model="hasSLAuction"
            hint="Display the Sec-Lending tab"
            inset
            label="Show Securities Lending"
            persistent-hint
          />

          <v-switch
            v-if="
              clientConfig.demoMode ||
              !clientConfig.systemProdLike ||
              clientConfig.systemEnv === 'sandbox'
            "
            v-model="orderbookEnabledOverride"
            hint="Enable the pre-production Orderbook instead of the production-live Supply & Demand functionality"
            inset
            label="Orderbook"
            persistent-hint
          />
        </v-col>
      </v-row>

      <v-row v-if="clientConfig.demoMode">
        <v-col>
          <h2>Testing</h2>

          <v-switch
            v-model="forceMarketTimeframesCheckingEvenWhenDisabled"
            :disabled="clientConfig.marketTimeframesEnabled"
            hint="Enforce the market timeframes on actions"
            inset
            label="Enforce Market Timeframes"
            persistent-hint
          />

          <v-switch
            v-model="forceMarketTimeframesClosed"
            hint="Force all market timeframes being closed"
            inset
            label="Force Market Timeframes closed"
            persistent-hint
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { mapMutations, mapState } from 'vuex';
import { ClientConfig, UXConfig } from '@/utils/helpers/rest';
import { extend } from 'lodash';

@Component({
  methods: {
    ...mapMutations(['updateUXConfig']),
  },
  computed: {
    ...mapState(['uxConfig', 'clientConfig']),
  },
})
export default class DesktopConfiguration extends Vue {
  // store state refs
  private uxConfig!: UXConfig;
  private clientConfig!: ClientConfig;

  // store mutations
  private updateUXConfig!: (config: UXConfig) => void;

  protected get hasGlobalSearch(): boolean {
    return this.uxConfig.hasGlobalSearch;
  }

  protected set hasGlobalSearch(hasGlobalSearch: boolean) {
    const config: UXConfig = extend({}, this.uxConfig, { hasGlobalSearch });
    this.updateUXConfig(config);
  }

  protected get hasNewsTicker(): boolean {
    return this.uxConfig.hasNewsTicker;
  }

  protected set hasNewsTicker(hasNewsTicker: boolean) {
    const config: UXConfig = extend({}, this.uxConfig, { hasNewsTicker });
    this.updateUXConfig(config);
  }

  protected get hasSLAuction(): boolean {
    return this.uxConfig.hasSLAuction;
  }

  protected set hasSLAuction(hasSLAuction: boolean) {
    const config: UXConfig = extend({}, this.uxConfig, { hasSLAuction });
    this.updateUXConfig(config);
  }

  protected get orderbookEnabledOverride(): boolean {
    return this.uxConfig.orderbookEnabledOverride !== null
      ? this.uxConfig.orderbookEnabledOverride
      : this.clientConfig.orderbookEnabled;
  }

  protected set orderbookEnabledOverride(orderbookEnabledOverride: boolean) {
    const config: UXConfig = extend({}, this.uxConfig, { orderbookEnabledOverride });
    this.updateUXConfig(config);
  }

  protected get forceMarketTimeframesCheckingEvenWhenDisabled(): boolean {
    return (
      this.uxConfig.forceMarketTimeframesCheckingEvenWhenDisabled ||
      this.clientConfig.marketTimeframesEnabled
    );
  }

  protected set forceMarketTimeframesCheckingEvenWhenDisabled(force: boolean) {
    const config: UXConfig = extend({}, this.uxConfig, {
      forceMarketTimeframesCheckingEvenWhenDisabled: force,
    });
    this.updateUXConfig(config);
  }

  protected get forceMarketTimeframesClosed(): boolean {
    return this.uxConfig.forceMarketTimeframesClosed;
  }

  protected set forceMarketTimeframesClosed(forceMarketTimeframesClosed: boolean) {
    const config: UXConfig = extend({}, this.uxConfig, { forceMarketTimeframesClosed });
    this.updateUXConfig(config);
  }
}
</script>

<style lang="scss" scoped></style>
